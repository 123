import React, { useEffect, useState } from "react";
import { DashbaordUpperCard } from "../../components";
import { Paper, Stack } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { dashboardCount } from "../../redux/UserReducer";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardCount_Data } = useSelector((store) => store.userStore);

  const iconStyle = [
    {
      fontSize: "5rem",
      color: " rgba(0, 0, 0, 0.5)",
    },
    {
      "&:hover": {
        transform: "scale(1.5)",
        marginRight: "0.3rem",
        transition: "all .5s",
      },
    },
  ];
  useEffect(() => {
    dispatch(dashboardCount());
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Stack direction="row" spacing={3}>
        <DashbaordUpperCard
          backgroudnColor={"#00C0EF"}
          icon={<GroupsIcon sx={iconStyle} />}
          number={dashboardCount_Data?.Totaluser}
          text={`Total Users`}
        />
        <DashbaordUpperCard
          backgroudnColor={"#FF851B"}
          icon={<GroupsIcon sx={iconStyle} />}
          number={dashboardCount_Data?.totalmembership}
          text={"Total Membership"}
        />
        <DashbaordUpperCard
          icon={<GroupsIcon sx={iconStyle} />}
          backgroudnColor={"#DD4B39"}
          number={dashboardCount_Data?.totalIco}
          text={"Total ICO"}
        />
        <DashbaordUpperCard
          icon={<GroupsIcon sx={iconStyle} />}
          backgroudnColor={"#00A65A"}
          number={dashboardCount_Data?.Totalwithdrawl}
          text={"Total Withdrawl"}
        />
      </Stack>
      {/* <Stack direction={"row"} spacing={3} sx={{ my: "2rem" }}>
        <Paper sx={{ p: "1rem", width: "100%" }}>
          <Chart
            options={{
              chart: {
                id: "apexchart-example",
              },
              xaxis: {
                categories: [
                  1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                ],
              },
            }}
            series={[
              {
                name: "Month-1",
                data: [140, 40, 75, 50, 79, 30, 70, 91, 125],
              },
            ]}
            type="bar"
            width={"100%"}
            height={320}
          />
        </Paper>
        <Paper sx={{ p: "1rem", width: "100%" }}>
          <Chart
            options={{
              chart: {
                width: 380,
                type: "donut",
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270,
                },
              },
              dataLabels: {
                enabled: true,
              },
              fill: {
                type: "gradient",
              },
              legend: {
                formatter: function (val, opts) {
                  return val + " - " + opts?.w?.globals?.series[opts.seriesIndex];
                },
              },
              title: {
                text: "Gradient Donut with custom Start-angle",
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            type="donut"
            width={"100%"}
            height={320}
            series={[44, 55, 41, 17, 15]}
          />
        </Paper>
      </Stack> */}
    </div>
  );
};

export default Dashboard;
